import React from "react";
import Data from "../../Data/Data.json";
import { HeroBackground } from "../Images/Images";

const Footer = () => {
  return (
    <div class="footer">
        <div class="container">
          <div class="row">
          <div class="col-md-6 col-lg-4">
                  <div class="footer-contact">
                    <h2>Salon Address</h2>
                    <p>
                      <i class="fa fa-map-marker-alt"></i>
                      28a High Street, Kingswood, BS15 4AJ
                    </p>
                    <p>
                      <i class="fa fa-phone-alt"></i>+44 7933 752257
                    </p>
                    <p>
                      <i class="fa fa-envelope"></i>info@smartstyling.com
                    </p>
                    <div class="footer-social">
                    
                      <a href="">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                      <a href="">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="footer-link">
                    <h2>Quick Links</h2>
                    
                    <a href="/privacy">Privacy policy</a>
                    <a href="/about">About us</a>
                    <a href="/contact">Contact us</a>
                  </div>
                </div>
            
            <div class="col-lg-4 col-md-6">
              <div class="footer-newsletter">
                <h2>Timings</h2>

                {Data.Timings.map((timm,index)=>{
                  return(
                    <p className="d-block">
                      <span>{timm.Days}</span><br/>
                      <span>{timm.Time}</span>
                    </p>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div class="container copyright">
          <div class="row">
            <div class="col-md-6">
              <p>
                &copy; <a href="/">{Data.Name}</a>, All Right Reserved.
              </p>
            </div>
            <div class="col-md-6">
              <p>
                Designed By <a href="https://www.dazzlingwebplanet.com">Dazzling Web Planet</a>
              </p>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Footer;
