import React from "react";
import { useLocation } from "react-router-dom";
import Data from "../../Data/Data.json";
import { Icons } from "../Images/Images";
import { Industries } from "../Images/Images";
import AliceCarousel from 'react-alice-carousel';
import "./Common.css";
import 'react-alice-carousel/lib/alice-carousel.css';
const Services = (props) => {
  const path = useLocation();
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 5 },
};

  const currentPath = !path.pathname.includes("industries");
  

  const Items = Data.Industries.map((industry, index) => {
    return (
      <div class="">
        <div class="item">
          
          <div class="inner text-black">
          <img src={Industries[index]} className="inner"/>
          </div>
          <div className="hovertext">
            {industry.Name}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="py-5">
      <div className="container">
        <div className="col-lg-12">
          <h1 className="mb-4 d-flex justify-content-center d-blk z-9">
            <span className={`text-primary me-2`}>Industries</span>&nbsp;We
            Serve
          </h1>
        </div>
        <div className="g-4 GridP">
          <section class=" text-center">
            <div class="innerContainer">
              <p class="paddingBottom45 industries">
                Business isn't one size fits all. Every industry requires a
                custom solution.
                <br />
              </p>
              <div class="ct-tabs-outer js-tabs">
                <div class="row text-center">
                <AliceCarousel  mouseTracking
                autoPlay
        infinite
        autoPlayInterval={1000}
        animationDuration={1500}
        disableDotsControls
        controlsStrategy="responsive"
         responsive={responsive} items={Items} 
         paddingLeft=""
        
         renderPrevButton={() => {
            return <p className="position-absolute start--10 top-50 pe-auto translate-middle arrow-left" role={"button"} ><i class="fas fa-caret-left fs-1"></i></p>
          }}
          renderNextButton={() => {
            return <p className="position-absolute end--10 top-50 pe-auto translate-middle arrow-right" role={"button"}><i class="fas fa-caret-right fs-1"></i></p>
          }}
        />
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="d-flex justify-content-end mb-4">
          {/* {currentPath &&<a className="btn btn-primary py-2 px-4 mt-2 ft-black" href="/industries">Show More</a>} */}
        </div>
      </div>
    </div>
  );
};

export default Services;
