import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function SEO() {
  return (
    <HelmetProvider>
    <div>
      <Helmet>
        <title>Smart Styling Studio | Bristol</title>
        <meta name="description" content="Smart Styling Studio is a unisex salon offering a wide range of services, from haircuts and hair coloring to bridal styling and hair extensions." />
        <meta name="keywords" content="Smart Styling Studio, Bristol, England, Southampton, Barber, Bridal, Groom, Bride, Hair, Salon"/>
        <meta property="og:title" content="Smart Styling Studio" />
        <meta property="og:description" content="Smart Styling Studio is a unisex salon offering a wide range of services, from haircuts and hair coloring to bridal styling and hair extensions." />
        <meta property="og:image" content="https://www.smartstylingstudio.com/images/smartstylingstudio.png" />
        <meta property="og:url" content="https://www.smartstylingstudio.com/about" />
        <meta property="og:type" content="website" />
      </Helmet>
      {/* rest of your component code here */}
    </div>
    </HelmetProvider>
  );
}

export default SEO;
