import * as React from "react";
import Grid from "@mui/material/Grid";
import { Paper, Box, Typography, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";

export default function AddressForm() {
  const form = React.useRef();
  const [Error, setError] = React.useState("");
  const [col, setCol] = React.useState("green");

  const oSubmit = (e) => {
    e.preventDefault();
    const reg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const preg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    const mailId = form.current.querySelectorAll("input#Email")[0].value;
    const phone = form.current.querySelectorAll("input#Phone")[0].value;

    const Err = mailId.match(reg) ? (phone.match(preg) ? "" : "Please Enter a Valid Phone Number") : "Please Enter a Valid Email Address";



    if (Err) {
      setError(Err);
      setCol("Red");
    } else {
      emailjs
        .sendForm(
          "service_3bysh55",
          "template_bmt7bao",
          form.current,
          "6SRcBUoirRQtcK-Sr"
        )
        .then(
          (result) => {
            setError("Enquiry Sent");
            setCol("Green");
            form.current.reset();
          },
          (error) => {
            setCol("Red");
            setError(error.text);
          }
        );
    }
  };

  return (
    <React.Fragment>
      {/* <Typography variant="h6" gutterBottom>
        Shipping address
      </Typography> */}
      <Paper>
        <Box px={3} py={2}>
          <form ref={form} onSubmit={oSubmit} className="row contact-form">
            <TextField
              required
              id="Name"
              name="Name"
              label="Name"
              autoComplete="given-name"
              variant="standard"
              className="col-12 mx-1 py-1"
            />

            <TextField
              required
              id="Email"
              name="Email"
              label="Email Address"
              className="col-12 mx-1 py-1"
              autoComplete="shipping address-line1"
              variant="standard"
            />
            {/* <Typography variant="inherit" color="textSecondary">
                {errors.email?.message}
              </Typography> */}

            <TextField
              id="Phone"
              name="Phone"
              label="Phone Number"
              className="col-12 mx-1 py-1"
              required
              autoComplete="shipping address-line2"
              variant="standard"
            />


            <TextField
              id="Query"
              name="Query"
              label="Tell us about your Query"
              className="col-12 mx-1 py-1"
              autoComplete="Query"
              variant="standard"
            />
            <Typography variant="inherit" color={col} className="mb-2">
              {Error}
            </Typography>
            <Box mt={3}>
              <Button
                className="btn"
                onClick={oSubmit}>
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Paper>
    </React.Fragment>
  );
}
