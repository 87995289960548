import React from "react";
import Data from "../../Data/Data.json";
import { PricingI, PricingW } from "../Images/Images";

const Pricing = () => {
  return (
    <div class="price">
      <div class="container">
        <div class="section-header text-center">
          <p>Our Best Pricing</p>
          <h2>We Provide Best Price in the City</h2>
        </div>
        <div className="d-flex justify-content-center">
          <a class="btn" href="#">
            For Men
          </a>
        </div>
        <div class="row">
          {Data.Pricing.map((Service, index) => {
            return (
              <div class="col-lg-3 col-md-4 col-sm-6" key={index}>
                <div class="price-item">
                  <div class="price-img">
                    <img src={PricingI[index]} alt="Image" height={100} width={100}/>
                  </div>
                  <div class="price-text">
                    <h2>{Service.id}</h2>
                    <h3>{Service.Price}</h3>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content-center">
        <a class="btn" href="#">
          For Women
        </a>
        </div>
        <div class="row">
          {Data.PricingW.map((Service, index) => {
            return (
              <div class="col-lg-3 col-md-4 col-sm-6" key={index}>
                <div class="price-item">
                  <div class="price-img">
                    <img src={PricingW[index]} alt="Image" height={100} width={100}/>
                  </div>
                  <div class="price-text">
                    <h2>{Service.id}</h2>
                    <h3>{Service.Price}</h3>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Pricing;
