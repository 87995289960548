import React from "react";
import { Testimonials } from "../Images/Images";
import { Carousel } from "react-responsive-carousel";
import Data from "../../Data/Data.json";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  
  return (
    <div class="testimonial">
      <div class="container">
        <div class="owl-carousel testimonials-carousel" >
          <Slider {...settings}>
          {Data.Testimonials.map((testimonial, index) => {
            return (
              <div class="testimonial-item" key={index}>
                <img src={Testimonials[index]} alt="Image" />
                <p>
                  {testimonial.Testimonial}
                </p>
                <h2>{testimonial.Name}</h2>
                <h3>{testimonial.Profession}</h3>
              </div>
            );
          })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
