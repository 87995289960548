import Testimonial1   from "../../img/testimonial-1.jpg";
import Testimonial2   from "../../img/testimonial-2.jpg";
import Testimonial3   from "../../img/testimonial-3.jpg";
import Testimonial4   from "../../img/testimonial-4.jpg";
import HeroPng        from "../../img/hero1.png";
import BGHero         from "../../img/bg-hero11.jpeg";
import About1         from "../../img/lionabout-1.jpeg";
import About2         from "../../img/lionabout-2.jpeg";
import About3         from "../../img/lionabout-3.jpeg";
import About4         from "../../img/lionabout-4.jpeg";
import Team1          from "../../img/team-1.jpg";
import Team2          from "../../img/team-2.jpg";
import Team3          from "../../img/team-3.jpg";
import Team4          from "../../img/team-4.jpg";
import Menu           from "../../img/lionmenu.png";
import About          from "../../img/about.jpg";

//Icons
import Customer       from "../../img/customer.png";
import Pickup         from "../../img/pickup.png";
import Order          from "../../img/order.png";
import Mobile         from "../../img/mobhand.png";
import Marketing      from "../../img/marketing.png";
import Website        from "../../img/website.png";
import Delivery       from "../../img/delivery.png";
import Retail         from "../../img/retail.png";
import Art            from "../../img/art.png";
import Cafe           from "../../img/cafe.png";
import Food           from "../../img/food.png";
import Hospitality    from "../../img/hospitality.png";
import Media          from "../../img/media.png";
import Takeaway       from "../../img/takeaway.png";

//Features
import WebApp         from "../../img/WebApp.png";
import App            from "../../img/App.png";
import Market         from "../../img/Market.png";
import AllFeatures    from "../../img/Feature.gif";

//Contact
import Contact        from "../../img/Contact.png";

//Services
import Services       from "../../img/Services.png";

//Clients
import GrilletWebp    from "../../img/grillet.webp";
import KaliPng        from "../../img/kali.png";
import DunningsWebp   from "../../img/dunnings.webp";


import Agricultural from "../../img/Agricultural.jpg";
import Building from "../../img/Building.jpg";
import Engineering from "../../img/Engineering.jpg";
import Farmers from "../../img/Farmers.jpg";
import Finance from "../../img/Finance.jpg";
import Franchises from "../../img/Franchises.jpg";
import Landlords from "../../img/Landlords.jpg";
import Manufacturing from "../../img/Manufacturing.jpg";
import Marine from "../../img/Marine.jpg";
import Professionals from "../../img/Professionals.jpg";
import Pubs from "../../img/Pubs.jpg";
import Parks from "../../img/Parks.jpg";
import RetailI from "../../img/Retail.jpg";
import IndustriesI from "../../img/Industries.jpg";
import Software from "../../img/Software.jpg";
import Manymore from "../../img/Manymore.jpg";



//Services
import Service1  from "../../img/Service1.jpg";
import Service2  from "../../img/Service2.jpg";
import Service3  from "../../img/Service3.jpg";
import Service4  from "../../img/Service4.jpg";
import Service5  from "../../img/Service5.jpg";
import Service6  from "../../img/Service6.jpg";
import Service7  from "../../img/Service7.jpg";
import Service8  from "../../img/Service8.jpg";
import Service9  from "../../img/Service9.jpg";
import Service10 from "../../img/Service10.jpg";
import Service11 from "../../img/Service11.jpg";
import Service12 from "../../img/Service13.jpg";
import Service13 from "../../img/Service13.jpg";
import Service14 from "../../img/Service14.jpg";
import Service15 from "../../img/Service15.jpg";
import Service16 from "../../img/Service16.jpg";
import Service17 from "../../img/Service17.jpg";
import Service18 from "../../img/Service18.jpg";
import Service19 from "../../img/Service19.jpg";
import Service20 from "../../img/Service20.jpg";
import Service21 from "../../img/Service21.jpg";

import Service1M from "../../img/service-1.jpg";
import Service2M from "../../img/service-2.jpg";
import Service3M from "../../img/service-3.jpg";

import Service1W from "../../img/servicew-1.jpg";
import Service2W from "../../img/servicew-2.jpg";
import Service3W from "../../img/servicew-3.jpg";


// Logo

import logo from "../../img/logo.png";



//Pricing

import Price1   from "../../img/price-1.png";
import Price2   from "../../img/price-2.png";
import Price3   from "../../img/price-3.png";
import Price4   from "../../img/price-4.png";
import Price5   from "../../img/price-5.png";
import Price6   from "../../img/price-6.png";
import Price7   from "../../img/price-7.png";
import Price8   from "../../img/price-8.png";
import Price9   from "../../img/price-9.png";
import Price10  from "../../img/price-10.png";

//PricingW
import Pricew1   from "../../img/pricew-1.png";
import Pricew2   from "../../img/pricew-2.png";
import Pricew3   from "../../img/pricew-3.png";
import Pricew4   from "../../img/pricew-4.png";
import Pricew5   from "../../img/pricew-5.png";
import Pricew6   from "../../img/pricew-6.png";

//Exports


export const Logo           = logo;
export const Aboutus        = About;
export const MenuImg        = Menu;
export const Abouts         = [About2, About3, About4, About2];
export const Testimonials   = [Testimonial1, Testimonial2, Testimonial3, Testimonial4];
export const Teams          = [Team1, Team2, Team3, Team4, Team4];
export const HeroBackground = HeroPng;
export const Icons          = [Order, Takeaway, Pickup, Mobile];
export const Features       = [WebApp, App, Market];
export const FeaturesWrap   = AllFeatures;
export const ContactUs      = Contact;
export const Industries     = [Agricultural, Building, Engineering, Farmers, Finance, Franchises, Landlords, Manufacturing, Marine, Professionals, Pubs, Parks, RetailI, IndustriesI, Software, Manymore];
export const ServiceBanner  = Services;
export const BgHero         = BGHero;
export const ServicesM      = [Service1M, Service2M, Service3M];
export const ServicesW      = [Service1W, Service2W, Service3W];
export const ServicesI      = [Service1, Service2, Service3, Service4, Service5, Service6, Service7, Service8, Service9, Service10, Service11, Service12, Service13, Service14, Service15, Service16, Service17, Service18, Service19, Service20, Service21];
export const PricingI       = [Price1, Price2, Price3, Price4, Price5, Price6, Price7, Price8, Price9, Price10];
export const PricingW       = [Pricew1, Pricew2, Pricew3, Pricew4, Pricew5, Pricew6];
export const Clients        = {
   "KaliMirchi" : {
        "Webp" : "",
        "Png"  : KaliPng
    },
    "Grillet" :
    {
        "Webp" : GrilletWebp,
        "Png"  : ""
    },
    "Dunnings" :
    {
        "Webp" : DunningsWebp,
        "Png"  : ""  
    }
};

