import React from "react";
import { useLocation } from "react-router-dom";
import Data from "../../Data/Data.json";
import AddressForm from "../Checkout/AddressForm";
import Locations from "../Locations/locations";

const Contact = (props) => {
  const path = useLocation();
  
  return (
    <div className="contact">
      <div className="container-fluid">
      <div className="py-5 px-0 animate fadeInUp container" data-wow-delay="0.1s">
        <div className="row g-0">
      
          <div className="col-md-4">
          {/* {!path.pathname.includes("contact") ?<h5 className="section-title ff-secondary text-start text-primary fw-normal">
                Contact Us
              </h5>: ""} */}
            {/* <iframe
  width="100%"
  height="100%"
  frameborder="0" 
  referrerpolicy="no-referrer-when-downgrade"
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d161148.9535784567!2d-1.6128805601623084!3d50.86908096752353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487473420ffa7663%3A0x488b0161308d5564!2sUnderwood+Barron!5e0!3m2!1sen!2suk!4v1545136922151"
  allowfullscreen>
</iframe> */}
          </div>
          <div className="col-md-8  z-9">
            <div className="p-5 wow fadeInUp" data-wow-delay="0.2s">
              
              <h2 className="mb-4">Get in Touch </h2>
              <div className="row g-3 text-white">
                <AddressForm/>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div className="row"> 
            {/* <Locations/> */}
        </div>
      </div>
    </div>
  );
};
export default Contact;

