import React from "react";
import Data from "../../Data/Data.json";
import { Logo } from "../Images/Images";


const Navbar = (props) => {
return(
  <div class="navbar navbar-expand-lg bg-dark navbar-dark">
    <div class="container-fluid">
      <a href="/" class="navbar-brand">
        <img src={Logo} alt={Data.Name}/> <span></span>
      </a>
      <button
        type="button"
        class="navbar-toggler"
        data-toggle="collapse"
        data-target="#navbarCollapse">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div
        class="collapse navbar-collapse justify-content-between"
        id="navbarCollapse">
        <div class="navbar-nav ml-auto">
          <a href="/" class="nav-item nav-link">
            Home
          </a>
          <a href="/about" class="nav-item nav-link">
            About
          </a>
          <a href="/service" class="nav-item nav-link">
            Service
          </a>
          <a href="/price" class="nav-item nav-link">
            Price
          </a>
          <a href="/contact" class="nav-item nav-link">
            Contact
          </a>
        </div>
      </div>
    </div>
  </div>);
};

export default Navbar;