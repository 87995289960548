import About from "../About/About";
import Contact from "../Contact/Contact";
import Services from "../Common/Services";
import Work from "../Common/Work";
import Service from "../Services/Service";
import ClientSlider from "../Common/ClientSlider";
import Comparsion from "../Common/Comparison";
import Team from "../Team/team";
import Serv from "../Services/index";
import Availability from "../Availability";
import HeroPng from "./img/hero.png";
import Pricing from "../Common/Pricing";
import Testimonial from "../Testimonial/Testimonial";
import Block from "../Common/Block";

const Home = () => {
  return (
    <>
      {/* <ClientSlider/> */}
      
      {/* <Work/> */}
      {/* <Comparsion/> */}
      
      {/* <Contact /> */}
      {/* <Services/> */}
      {/* {<Team/>} */}
      {/* <Serv/> */}
      {/* <Services/>
      <Availability/> */}
    <About />
    <Service/>
    {<Pricing/>}
    <Testimonial/>

     

      <Contact/>

    
    

    
    </>
  );
};

export default Home;
