import React from "react";
import { useLocation } from "react-router-dom";
import Data from "../../Data/Data.json";
import BookDemo from "../Common/BookDemo";
import { Aboutus } from "../Images/Images";
import Team from "../Team/team";

const About = (props) => {
  const path = useLocation();

  return (
    <>
      <div class="about">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5 col-md-6">
              <div class="about-img">
                <img src={Aboutus} alt="Image" />
              </div>
            </div>
            <div class="col-lg-7 col-md-6">
              <div class="section-header text-left">
                <p>Learn About Us</p>
                <h2>{Data.Name}</h2>
              </div>
              <div class="about-text">
                {!path.pathname.includes("about") ? (
                  <>
                    <p>{Data.About[0].desc}</p>
                    <p>{Data.About[1].desc}</p>
                  </>
                ) : (
                  Data.About.map((about, index) => {
                    return about.type == "heading" ? (
                      <h5 key={index}>{about.desc}</h5>
                    ) : (
                      <p key={index}>{about.desc}</p>
                    );
                  })
                )}
                {!path.pathname.includes("about") ? (
                  <a class="btn" href="/about">
                    Learn More
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {path.pathname.includes("about") ? <Team/>: ""} */}
    </>
  );
};

export default About;
