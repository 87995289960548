import React from "react";
import { useLocation } from "react-router-dom";
import Data from "../../Data/Data.json";
import { Industries } from "../Images/Images";
const Availability = (props) => {

  return (
    <div className="py-5 bg-grey">
      <div className="container">
        <div className='col-lg-12'>
          <h1 className="mb-4 d-flex justify-content-center d-blk z-9">
          When Are We <span className={`text-primary me-2`}>&nbsp;Available</span>
          </h1>
        </div>
        <div className="g-4 GridP">
          <section class=" text-center">
            <div class="innerContainer">
            <p class="paddingBottom45 availability">
            Usual office hours and at other times by appointment, either at our offices in Chandlers Ford, Lymington and Tadley or at your premises.<br/>
              </p>
              <div class="ct-tabs-outer js-tabs">
                <div class="row text-center">
                  {Data.Timings.map((Timing,index)=>{
                    return(  <div class="col-md-12 col-sm-12 col-xs-12">
                        <div className="d-flex justify-content-evenly">
                            <p className="timings">{Timing.Days}</p>
                            <p className="timings">{Timing.Time}</p>
                            </div>
                            <hr/>
                    </div>);
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="d-flex justify-content-end mb-4">
        {/* {currentPath &&<a className="btn btn-primary py-2 px-4 mt-2 ft-black" href="/industries">Show More</a>} */}
            </div>
      </div>
    </div>

  );
};

export default Availability;

