import React, { useEffect, useRef } from "react";
import Data from "../../Data/Data.json";
const BookDemo = () => {
  return (
    <>
      <div className="mt-2 wf-section nav-bg mb-1" >
        <div className="container">
        <div className="row">
          <div className="col-md-8 inline flex">
            <div
              data-w-id="463734d7-5cd3-b595-1e0a-c59c6583f0d3"
              className="align-middle titleDemo">
              Book a <span className="demotitle-blue">{Data.Name} </span> product demo
            </div>
          </div>
          <div className="col-md-4 btn-demo d-flex justify-content-end">
            {/* <img
              src="https://uploads-ssl.webflow.com/5e5a87a53c3e0f76a5eba371/611255c1b99302e48b345f11_DemoHandwriting.png"
              loading="lazy"
              data-w-id="463734d7-5cd3-b595-1e0a-c59c6583f0d9"
              alt="See it in action"
              className="image-23"
            /> */}
            <a href="/contact" className="btn btn-primary py-2 px-4 m-auto ft-18 ft-black">Tip Bits</a>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BookDemo;
