import React from "react";
import { useLocation } from "react-router-dom";
import Data from "../../Data/Data.json";
import { Industries } from "../Images/Images";
const Locations = (props) => {

  return (
    <div className="py-5 bg-grey mt-5">
      <div className="container">
        <div className='col-lg-12'>
          <h1 className="mb-4 d-flex justify-content-center d-blk z-9">
          Where Are We <span className={`text-primary me-2`}>&nbsp;Available</span>
          </h1>
        </div>
        <div className="g-4 GridP">
          <section class="text-center">
            <div class="innerContainer">
              <div class="ct-tabs-outer js-tabs">
                <div class="row text-center">
                  {Data.Locations.map((loc,index)=>{
                    return(  <div class="col-md-4 col-sm-4 col-xs-12">
                        <div className="d-flex justify-content-start flex-column locations">
                            <p className="text-capitalize fw-bold">{loc.place}</p>
                            <p>{loc.name}</p>
                            <p>{loc.contact}</p>
                            </div>
                            <hr/>
                    </div>);
                  })}
                </div>
              </div>
              <p class="pt-2 fs-6 availability">
            Underwood Barron LLP is registered to carry on audit work in the UK and Ireland and regulated for a range of investment business activities by the The Institute of Chartered Accountants in England and Wales.
              </p>
            </div>
          </section>
        </div>
        <div className="d-flex justify-content-end mb-4">
        {/* {currentPath &&<a className="btn btn-primary py-2 px-4 mt-2 ft-black" href="/industries">Show More</a>} */}
            </div>
      </div>
    </div>

  );
};

export default Locations;

