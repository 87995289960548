import React from "react";
import { Teams } from "../Images/Images";
import Data from "../../Data/Data.json";

const Team = () => {
  return (
    <div class="team">
    <div class="container">
      <div class="section-header text-center">
        <p>Our Barber Team</p>
        <h2>Meet Our Hair Cut Expert Barber</h2>
      </div>
      <div class="row justify-content-center">
        {
          Data.Team.map((Member,index)=>{
            return(<div class="col-lg-3 col-md-6" key={index}>
            <div class="team-item">
              <div class="team-img">
                <img src={Teams[index]} alt="Team Image" />
              </div>
              <div class="team-text">
                <h2>{Member.Name}</h2>
                <p>{Member.Designation}</p>
              </div>
            </div>
          </div>)
          })
        }
      </div>
    </div>
  </div>
  );
};
export default Team;
