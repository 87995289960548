import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { HeroBackground } from "../Images/Images";
import Data from "../../Data/Data.json";
import TrustPilot from "../Common/TrustPilot";
import BannerV from "../../img/banner.mp4";

const Banner = () => { 
    const path = useLocation();
    const [Breadcrumb,setBreadcrumb] = useState([{id:"",value:""}]);
    
    useEffect(()=>{
        Data.Nav.forEach((key)=>{
            if(path.pathname.includes(key.id.toLowerCase())){
                setBreadcrumb({id:key.id,value:key.Value})
            }
        })
    },[])

    return(
    
    <>
        {path.pathname == "/"?
       <div class="hero">
       <div >
         <div class="row">
           <div class="col-sm-12 col-md-6">
             <div class="hero-text">
               <h1>{Data.Name}</h1>
               <p>
                 {Data.Banner_SubHead}
               </p>
               <a
                 class="btn"
                 href="/contact">
                 Get In Touch
               </a>
             </div>
           </div>
           <div class="col-sm-12 col-md-6 d-none d-md-block">
             <div class="hero-image">
               <img src={HeroBackground} alt="Hero Image" />
             </div>
           </div>
         </div>
       </div>
     </div>:
  <div class="page-header">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <h2>{Breadcrumb?.value}</h2>
          </div>
          <div class="col-12">
              <a href="">Home</a>
              <a href="">{Breadcrumb?.id}</a>
          </div>
      </div>
  </div>
</div>}
            {/* {<TrustPilot/>} */}
</>);
 
};
export default Banner;