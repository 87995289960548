import React from "react";
import "./Service.css";
import Data from "../../Data/Data.json";
import { ServiceBanner, ServicesM, ServicesW } from "../Images/Images";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const Service = () => {
  return (
    <>
    <div class="service">
        <div class="container">
          <div class="section-header text-center">
            <p>Our Salon Services</p>
            <h2>Best Salon and Barber Services for You</h2>
          </div>
          <div className="d-flex justify-content-center">
            <a class="btn" href="#">
              For Men
            </a>
          </div>
          <div class="row">
            {Data.ServicesI.map((Service,index)=>{
              return(<div class="col-lg-4 col-md-6" key={index}>
              <div class="service-item">
                <div class="service-img">
                  <img src={ServicesM[index]} alt="Image" />
                </div>
                <h3>{Service.Name}</h3>
                <p>
                  {Service.Description}
                </p>
              </div>
            </div>)
            })}
          </div>
          <div className="d-flex justify-content-center">
            <a class="btn" href="#">
              For Women
            </a>
          </div>
          <div class="row">
            {Data.ServicesW.map((Service,index)=>{
              return(<div class="col-lg-4 col-md-6" key={index}>
              <div class="service-item">
                <div class="service-img">
                  <img src={ServicesW[index]} alt="Image" />
                </div>
                <h3>{Service.Name}</h3>
                <p>
                  {Service.Description}
                </p>
              </div>
            </div>)
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
